
import Vue from 'vue';
import { mask } from 'vue-the-mask';
import utils from '../../../util';
import vue2Dropzone from 'vue2-dropzone';
import { format } from 'date-fns';
import { AdvertiserInfo } from '@point/utility-classes';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default Vue.extend({
  name: 'linearAdminAdvertiserInfo',
  directives: { mask },
  props: ['editRights'],
  data: (): {
    editMode: boolean;
    addImageToogle: boolean;
    editAdvertiser: AdvertiserInfo | null;
    advertiserImage: string;
    uploadError: string | null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectedConfig: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sections: any[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    templates: any[];
  } => ({
    editMode: false,
    addImageToogle: false,
    advertiserImage: '',
    editAdvertiser: null,
    sections: [],
    templates: [],
    selectedConfig: null,
    uploadError: null,
  }),
  components: {
    dropzone: vue2Dropzone,
  },
  created(): void {
    this.getAgencies();
    this.advertiserImage = this.advertiserInfo.advertiserLogo || '';
    this.$parent.$on('edit-business-details', () => {
      this.openEdit();

      setTimeout(() => {
        this.$refs.website.focus();
      }, 200);
    });
  },
  computed: {
    loading(): boolean {
      return (
        this.$store.state.advertiser?.isAdvertiserInfoLoading || this.$store.state.advertiser?.isUpdateAdvertiserLoading
      );
    },
    uploader() {
      return {
        paramName: 'image',
        acceptedFiles: '.png,.jpg,.gif,.jpeg',
        url: `/api/uploadCustomLogo/${this.advertiserInfo.id}`,
        clickable: true,
        dictDefaultMessage: 'Click or drop logo here',
        maxFiles: 1,
        thumbnailWidth: 500,
        thumbnailMethod: 'contain',
      };
    },
    advertiserInfo(): AdvertiserInfo | null {
      return this.$store.state.advertiser?.advertiserInfo?.data || null;
    },
    stations(): string {
      return this.advertiserInfo.stations.join(', ');
    },
    isAdmin(): boolean {
      return utils.hasRight(this.$store.state.customer.user, ['AGENCY_ADMIN']);
    },
    friendlyDateCreated(): string {
      if (!this.advertiserInfo.created_at) return '—';
      const parsedDate = new Date(this.advertiserInfo.created_at);
      return format(parsedDate, 'MM/d/yy');
    },
  },
  methods: {
    openEdit(): void {
      this.editMode = true;
      this.editAdvertiser = { ...this.advertiserInfo };
      if (this.editAdvertiser?.advertiserLogo && this.editAdvertiser.advertiserLogo !== '') {
        this.advertiserImage = this.editAdvertiser.advertiserLogo;
      }
    },
    closeEdit(): void {
      this.editMode = false;
    },
    closeDialog(cancel: boolean): void {
      if (cancel) {
        this.editAdvertiser.advertiserLogo = this.advertiserInfo?.advertiserLogo || '';
        this.advertiserImage = this.advertiserInfo?.advertiserLogo || '';
        this.$refs.logoDropzone.removeAllFiles(true);
      }
      this.addImageToogle = false;
    },
    update(): void {
      if (typeof this.editAdvertiser === 'undefined') return;
      this.$emit('update-advertiser-info', this.editAdvertiser);
      this.editMode = false;
    },
    getAgencies(): void {
      this.$store.dispatch('agencyAdmin/getAgencies');
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    uploadsuccess(droppedfile: any, response: any): void {
      if (response) {
        const url = response.url;
        this.editAdvertiser.advertiserLogo = url;
        this.advertiserImage = url;
        this.uploadError = null;
      } else {
        this.uploadError = 'Something went wrong, please try again.';
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    uploaderror(droppedfile: any): void {
      if (droppedfile && !droppedfile.accepted) {
        // eslint-disable-next-line no-console
        console.log('uploaderror', droppedfile, `File ${droppedfile.name}, type not accepted`);
        this.uploadError = `File ${droppedfile?.name}, type not accepted`;
      } else {
        // eslint-disable-next-line no-console
        console.log('uploaderror', droppedfile);
        this.uploadError = `File ${droppedfile?.name} error: ${droppedfile.xhr.responseText}`;
      }
    },
    removeImage(): void {
      this.editAdvertiser.advertiserLogo = '';
      this.advertiserImage = '';
      this.$refs?.logoDropzone?.removeAllFiles(true);
    },
  },
});
